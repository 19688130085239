// @flow

import { type Axios } from 'axios'
import ClientOAuth2 from 'client-oauth2'
import Customer from '../CustomerArea/Customer'
import {
  type ConfigurationsPayload,
  type ConfigurationPayload,
  saveShoppingCart,
  fetchShoppingCartByLotId,
  fetchConfigurations,
  fetchConfiguration,
  deleteConfiguration,
  configurationCustomerContact,
  editProfilCustomer,
  editPasswordCustomer,
  fetchMe,
  loginCustomer,
  registerCustomer,
} from '../CustomerArea/api'
import ConfigurationShoppingCart from '../FlatConfigurator/MainMenu/Configuration/ShoppingCart/ConfigurationShoppingCart'
import { CustomerContextApi } from './CustomerContextApi'
import { attachCustomerToContext } from '../ErrorReporting'
import { GoogleAnalyticsService } from '../GoogleAnalytics'
import { writeToken } from '../CustomerArea/storage'

type DispatchCustomerUpdateTrigger = (newCustomer: Customer) => Promise<void>
type ShowRegistrationModalTrigger = (
  shoppingCart?: ConfigurationShoppingCart,
) => Promise<?Customer>
type ShowLoginModalTrigger = (cancelable: boolean) => Promise<?Customer>
type LogOutCustomerTrigger = () => Promise<void>

export default class ReactCustomerContextApi implements CustomerContextApi {
  +_httpClient: Axios
  +_authClient: ClientOAuth2
  +_googleAnalyticsService: GoogleAnalyticsService
  +_dispatchCustomerUpdate: DispatchCustomerUpdateTrigger
  +_showRegistrationModal: ShowRegistrationModalTrigger
  +_showLoginModal: ShowLoginModalTrigger
  +_logOutCustomer: LogOutCustomerTrigger

  customer: ?Customer

  constructor(
    httpClient: Axios,
    authClient: ClientOAuth2,
    googleAnalyticsService: GoogleAnalyticsService,
    customerRefreshed: DispatchCustomerUpdateTrigger,
    showRegistrationModal: ShowRegistrationModalTrigger,
    showLoginModal: ShowLoginModalTrigger,
    logOutCustomer: LogOutCustomerTrigger,
    customer: ?Customer,
  ) {
    this._httpClient = httpClient
    this._authClient = authClient
    this._googleAnalyticsService = googleAnalyticsService
    this._dispatchCustomerUpdate = customerRefreshed
    this._showRegistrationModal = showRegistrationModal
    this._showLoginModal = showLoginModal
    this._logOutCustomer = logOutCustomer

    this.customer = customer
  }

  replaceCustomer(newCustomer: ?Customer): CustomerContextApi {
    attachCustomerToContext(newCustomer)

    return new ReactCustomerContextApi(
      this._httpClient,
      this._authClient,
      this._googleAnalyticsService,
      this._dispatchCustomerUpdate,
      this._showRegistrationModal,
      this._showLoginModal,
      this._logOutCustomer,
      newCustomer,
    )
  }

  googleAnalyticsService(): GoogleAnalyticsService {
    return this._googleAnalyticsService
  }

  isAuthenticated(): boolean {
    return !!this.customer
  }

  async startRegistrationProcess(
    shoppingCart?: ConfigurationShoppingCart,
  ): Promise<?Customer> {
    this.customer = await this._showRegistrationModal(shoppingCart)

    return this.customer
  }

  async startLoginProcess(cancelable: boolean = true): Promise<?Customer> {
    this.customer = await this._showLoginModal(cancelable)

    return this.customer
  }

  async registerCustomer(
    programId: string,
    firstName: string,
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }> {
    const registrationResult = await this._registerCusthomer(
      programId,
      firstName,
      email,
      password,
    )

    this._googleAnalyticsService.reportEvent('account', 'create')

    return registrationResult
  }

  async logInCustomer(
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }> {
    const { customer, errors } = await this._logInCustomer(email, password)
    this.customer = customer

    this._googleAnalyticsService.reportEvent('account', 'login')

    return { customer, errors: errors }
  }

  async logOutCustomer() {
    return this._logOutCustomer()
  }

  async saveShoppingCart(
    shoppingCart: ConfigurationShoppingCart,
  ): Promise<string> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    const { customer, configurationId } = await saveShoppingCart(
      this._httpClient,
      this.customer,
      shoppingCart,
    )

    this._updateCustomerIfNeeded(customer)

    return configurationId
  }

  async fetchShoppingCartByLotId(
    lotId: string,
  ): Promise<?ConfigurationShoppingCart> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    const {
      customer,
      configurationShoppingCart,
    } = await fetchShoppingCartByLotId(this._httpClient, this.customer, lotId)

    this._updateCustomerIfNeeded(customer)

    return configurationShoppingCart
  }

  async fetchConfigurations(): Promise<ConfigurationsPayload> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    const { customer, configurationsPayload } = await fetchConfigurations(
      this._httpClient,
      this.customer,
    )

    this._updateCustomerIfNeeded(customer)

    return configurationsPayload
  }

  async fetchConfiguration(
    configurationId: string,
  ): Promise<ConfigurationPayload> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    const { customer, configurationPayload } = await fetchConfiguration(
      this._httpClient,
      this.customer,
      configurationId,
    )

    this._updateCustomerIfNeeded(customer)

    return configurationPayload
  }

  async deleteConfiguration(configurationId: string): Promise<void> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    const { customer } = await deleteConfiguration(
      this._httpClient,
      this.customer,
      configurationId,
    )

    this._updateCustomerIfNeeded(customer)
  }

  async _updateCustomerIfNeeded(newCustomer: Customer) {
    if (this.customer !== newCustomer) {
      await this._dispatchCustomerUpdate(newCustomer)
    }
  }

  async refreshCustomer(): Promise<Customer> {
    const customer = this.customer
    if (!customer) {
      throw new Error('No customer logged in')
    }

    const me = await fetchMe(this._httpClient, customer.authToken)

    const refreshedCustomer = customer.refreshCustomer(me.me)

    this._updateCustomerIfNeeded(refreshedCustomer)

    return refreshedCustomer
  }

  async configurationCustomerContact(
    configurationId: string,
    firstName: string,
    lastName: string,
    phone: string,
  ): Promise<Array<{| field: ?string, message: string |}>> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    return configurationCustomerContact(
      this._httpClient,
      this.customer,
      configurationId,
      firstName,
      lastName,
      phone,
    )
  }

  async editProfilCustomer(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  ): Promise<Array<{| field: ?string, message: string |}>> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    return editProfilCustomer(
      this._httpClient,
      this.customer,
      firstName,
      lastName,
      email,
      phone,
    )
  }

  async editPasswordCustomer(
    oldPassword: string,
    newPassword: string,
  ): Promise<Array<{| field: ?string, message: string |}>> {
    if (!this.customer) {
      throw new Error('No customer logged in')
    }

    return editPasswordCustomer(
      this._httpClient,
      this.customer,
      oldPassword,
      newPassword,
    )
  }

  async _registerCusthomer(
    programId: string,
    firstName: string,
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }> {
    const registrationResult = await registerCustomer(
      this._httpClient,
      this._authClient,
      programId,
      firstName,
      email,
      password,
    )

    if (registrationResult.errors) {
      return {
        customer: null,
        errors: registrationResult.errors,
      }
    }

    const { me, token } = registrationResult

    writeToken(token)

    return {
      customer: new Customer(
        token,
        me.id,
        me.email,
        me.firstName,
        me.lastName,
        me.phone,
      ),
      errors: [],
    }
  }

  async _logInCustomer(
    email: string,
    password: string,
  ): Promise<{
    customer: ?Customer,
    errors: Array<{| field: ?string, message: string |}>,
  }> {
    const logInResult = await loginCustomer(
      this._httpClient,
      this._authClient,
      email,
      password,
    )

    if (logInResult.errors) {
      return {
        customer: null,
        errors: logInResult.errors,
      }
    }

    const { me, token } = logInResult

    writeToken(token)

    return {
      customer: new Customer(
        token,
        me.id,
        me.email,
        me.firstName,
        me.lastName,
        me.phone,
      ),
      errors: [],
    }
  }
}
