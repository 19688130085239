// @flow
import * as React from 'react'
import Bowser from 'bowser'
import BrowserSupportPage from './GuidelinesNew/BrowserSupport'

type Props = {
  children: React.Node,
}

type State = {
  isCompatible: ?boolean,
}

class BrowserSupport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isCompatible: null,
    }
  }

  componentDidMount() {
    const currentBrowser = Bowser.getParser(window.navigator.userAgent)
    const isValidBrowser = currentBrowser.satisfies({
      // >0.2% not dead not ie <= 11 not op_mini all
      // desktop
      chrome: '>=49',
      edge: '>=17',
      firefox: '>=68',
      opera: '>=63',
      safari: '>=10.1',
      ie: '>11',
      // mobile
      and_ch: '>=78',
      and_ff: '>=68',
      and_qq: '>=1.2',
      and_uc: '>=12.12',
      samsung: '>=9.2',
      ios_saf: '>=11.3',
    })

    this.setState({
      isCompatible: isValidBrowser,
    })
  }

  render() {
    if (this.state.isCompatible === null) {
      return false
    }
    return typeof this.state.isCompatible === 'boolean' &&
      !this.state.isCompatible ? (
      <BrowserSupportPage />
    ) : (
      this.props.children
    )
  }
}

export default BrowserSupport
