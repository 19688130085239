// @flow

import { Token } from 'client-oauth2'
import type { MePayload } from './api'

export default class Customer {
  authToken: Token
  id: string
  email: string
  firstName: string
  lastName: ?string
  phone: ?string

  constructor(
    authToken: Token,
    id: string,
    email: string,
    firstName: string,
    lastName: ?string = null,
    phone: ?string = null,
  ) {
    this.authToken = authToken
    this.id = id
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.phone = phone
  }

  refreshToken(newAuthToken: Token): Customer {
    return new Customer(
      newAuthToken,
      this.id,
      this.email,
      this.firstName,
      this.lastName,
      this.phone,
    )
  }

  refreshCustomer(customer: MePayload): Customer {
    return new Customer(
      this.authToken,
      this.id,
      customer.email,
      customer.firstName,
      customer.lastName,
      customer.phone,
    )
  }
}
