// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
  >
    <path
      fill="#FFF"
      fillOpacity=".2"
      fillRule="evenodd"
      d="M10 20.5c5.523 0 10-4.477 10-10S15.523.5 10 .5 0 4.977 0 10.5s4.477 10 10 10zm1.095-8.062c.009-.346.057-.632.143-.857.087-.226.242-.446.465-.66l.786-.732c.333-.323.574-.64.725-.95.15-.31.225-.654.225-1.032 0-.866-.279-1.533-.837-2.003-.558-.47-1.345-.704-2.362-.704-1.02 0-1.814.254-2.379.762S7.01 7.48 7 8.392h2.31c.01-.342.099-.613.267-.814.169-.2.39-.3.663-.3.593 0 .889.343.889 1.032 0 .282-.088.54-.263.775-.176.235-.432.494-.77.776-.337.283-.58.617-.73 1.002-.151.385-.226.91-.226 1.575h1.955zm-.957 3.234c.364 0 .668-.108.912-.325.244-.216.366-.493.366-.83 0-.338-.122-.614-.366-.831-.244-.216-.548-.325-.912-.325-.365 0-.67.109-.913.325-.244.217-.366.493-.366.83 0 .338.122.615.366.831.244.217.548.325.913.325z"
    />
  </svg>
)
