// @flow

import * as React from 'react'
import styled from 'styled-components'
import { TextInputField } from '../../GuidelinesNew/Form/InputField'
import {
  Form,
  SubmitButton,
  ErrorMessage,
  SubmitButtonContainer,
} from '../../GuidelinesNew/Form'
import { RobotoMedium } from '../../GuidelinesNew/Typography/Fonts'
import { ModalTitle, ModalDefaultContent } from '../../GuidelinesNew/Modal'
import Customer from '../Customer'
import { FakeLink } from '../../GuidelinesNew/Typography/Link'
import { withTranslation } from 'react-i18next'

const Link = styled(FakeLink)`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.weight};
  line-height: 30px;
`

const LinksContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`

type OneErrorPayload = {| field: ?string, message: string |}

type Props = {|
  onLogIn: (
    email: string,
    password: string,
  ) => Promise<{
    customer: ?Customer,
    errors: OneErrorPayload[],
  }>,
  onLogInSuccess: (customer: Customer) => void,
  switchToRegistrationMode: () => any,
  switchToRequestNewPasswordMode: () => any,
  t: string => string,
|}

type State = {|
  logInInProgress: boolean,
  errors: OneErrorPayload[],
|}

class CustomerLogInForm extends React.Component<Props, State> {
  +emailFieldRef: { current: null | React.ElementRef<'input'> }
  +passwordFieldRef: { current: null | React.ElementRef<'input'> }

  constructor(props: Props) {
    super(props)

    this.emailFieldRef = React.createRef()
    this.passwordFieldRef = React.createRef()

    this.state = { errors: [], logInInProgress: false }
  }

  handleRegisterFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { onLogIn, onLogInSuccess } = this.props

    if (!this.emailFieldRef.current || !this.passwordFieldRef.current) {
      throw new Error('Unable to access to all the fields')
    }

    const email = this.emailFieldRef.current.value
    const password = this.passwordFieldRef.current.value

    this.setState({ logInInProgress: true, errors: [] }, async () => {
      const { customer, errors } = await onLogIn(email, password)

      if (errors.length > 0) {
        this.setState({
          logInInProgress: false,
          errors: errors,
        })

        return
      }

      if (!customer) {
        throw new Error('No errors in logIn process, but no customer neither')
      }

      this.setState({ logInInProgress: false, errors: [] }, () => {
        onLogInSuccess(customer)
      })
    })
  }

  getErrorByField(field: ?string): ?string {
    const error = this.state.errors.find(oneError =>
      field ? oneError.field === field : !oneError.field,
    )
    if (!error) {
      return null
    }

    return error.message
  }

  render() {
    const {
      switchToRegistrationMode,
      switchToRequestNewPasswordMode,
      t,
    } = this.props
    const { logInInProgress } = this.state

    const globalError = this.getErrorByField()
    const emailError = this.getErrorByField('email')
    const passwordError = this.getErrorByField('password')

    return (
      <ModalDefaultContent>
        <ModalTitle>{t('customerArea.loginForm.title')}</ModalTitle>

        <Form onSubmit={this.handleRegisterFormSubmit}>
          <TextInputField
            ref={this.emailFieldRef}
            type="text"
            name="email"
            label={t('customerArea.loginForm.email')}
            defaultValue={
              process.env.NODE_ENV === 'development'
                ? `customer@custhome.app`
                : null
            }
            errorMessage={emailError}
            autoComplete="email"
          />

          <TextInputField
            ref={this.passwordFieldRef}
            type="password"
            name="password"
            label={t('customerArea.loginForm.password')}
            defaultValue={
              process.env.NODE_ENV === 'development' ? '12345678' : null
            }
            errorMessage={passwordError}
            autoComplete="current-password"
          />

          {globalError ? <ErrorMessage>{globalError}</ErrorMessage> : null}

          <LinksContainer>
            <Link onClick={() => switchToRegistrationMode()}>
              {t('customerArea.loginForm.switchToRegister')}
            </Link>
            <Link onClick={() => switchToRequestNewPasswordMode()}>
              {t('customerArea.loginForm.switchToForgotPassword')}
            </Link>
          </LinksContainer>

          <SubmitButtonContainer>
            <SubmitButton fluid disabled={logInInProgress}>
              {t('customerArea.loginForm.submit')}
            </SubmitButton>
          </SubmitButtonContainer>
        </Form>
      </ModalDefaultContent>
    )
  }
}

export default withTranslation()(CustomerLogInForm)
