// @flow

import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'
import { PrimaryButton } from '../Button'
import IconCheckmarkBase from './../Icons/icon-checkmark-base.svg'
import IconCheckmarkHover from './../Icons/icon-checkmark-hover.svg'
import IconCheckmarkChecked from './../Icons/icon-checkmark-checked.svg'

export const Input = styled.input`
  width: 100%;
  height: 100%;
  margin: 15px 0;
  padding: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 18px;
  font-weight: ${RobotoMedium.weight};
  line-height: 20px;
  background-color: transparent;
  border: 0;
  color: ${Colors.white100};

  &::placeholder {
    color: ${Colors.greyBold};
  }

  &:first-child {
    margin-top: 0;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 4px 1000px ${Colors.background} inset;
    -webkit-text-fill-color: ${Colors.greyRegular} !important;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${Colors.white60};
  }
`

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  @supports (-webkit-appearance: none) {
    -webkit-appearance: none;

    width: 20px;
    height: 20px;
    margin: 0 14px;
    border: none;

    background: url("${IconCheckmarkBase}") no-repeat center center;
    cursor: pointer;

    &:hover {
      background-image: url("${IconCheckmarkHover}");
    }

	  &:focus {
      filter: drop-shadow(0px 0px 5px ${Colors.white30});
    }

    &:checked {
	    background-image: url("${IconCheckmarkChecked}");
    }

    /* Prefectch the background image to avoid a blink */
    &:after {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
      z-index: -1;
      content: url("${IconCheckmarkHover}")  url("${IconCheckmarkChecked}");
    }
  }
`

export const SubmitButton = styled(PrimaryButton).attrs({
  type: 'submit',
})`
  margin-top: 10px;
`

export const SubmitButtonContainer = styled.div`
  width: 175px;
  margin: 40px auto 0 auto;

  & ${SubmitButton} {
    margin: 0;
  }
`

export const Form = styled.form``

export const ErrorMessage = styled.div`
  padding: 20px 0 0 0;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.weight};
  font-size: 12px;
  color: ${Colors.red};
`
