// @flow

import * as React from 'react'
import { CustomerContextApi } from './CustomerContextApi'
import NullCustomerContextApi from './NullCustomerContextApi'

const CustomerContext = React.createContext<CustomerContextApi>(
  new NullCustomerContextApi(),
)

export default CustomerContext
