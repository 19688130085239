// @flow

import * as React from 'react'
import styled from 'styled-components'
import uniqid from 'uniqid'
import {
  Form,
  SubmitButton,
  SubmitButtonContainer,
} from '../../GuidelinesNew/Form'
import {
  TextInputField,
  CheckboxInputField,
} from '../../GuidelinesNew/Form/InputField'
import { RobotoMedium } from '../../GuidelinesNew/Typography/Fonts'
import { FakeLink, NativeLink } from '../../GuidelinesNew/Typography/Link'
import { ModalTitle, ModalDefaultContent } from '../../GuidelinesNew/Modal'
import Customer from '../Customer'
import { withTranslation } from 'react-i18next'

const Link = styled(FakeLink)`
  display: block;
  margin-top: 10px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.weight};
  line-height: 30px;
`

type OneErrorPayload = {| field: ?string, message: string |}

type Props = {|
  onRegistration: (
    firstName: string,
    email: string,
    password: string,
  ) => Promise<{
    customer: ?Customer,
    errors: OneErrorPayload[],
  }>,
  onRegistrationSuccess: (customer: Customer) => any,
  switchToLogInMode: () => any,
  privacyPoliciesPageUrl: ?string,
  termsOfServicesPageUrl: ?string,
  t: string => string,
|}

type State = {|
  registrationInProgress: boolean,
  errors: OneErrorPayload[],
  uid: string,
  rgpdConditionAccepted: boolean,
|}

class CustomerRegistrationForm extends React.Component<Props, State> {
  +firstNameFieldRef: { current: null | React.ElementRef<'input'> }
  +emailFieldRef: { current: null | React.ElementRef<'input'> }
  +passwordFieldRef: { current: null | React.ElementRef<'input'> }

  constructor(props: Props) {
    super(props)

    this.firstNameFieldRef = React.createRef()
    this.emailFieldRef = React.createRef()
    this.passwordFieldRef = React.createRef()

    this.state = {
      errors: [],
      registrationInProgress: false,
      uid: uniqid(),
      rgpdConditionAccepted: false,
    }
  }

  handleRegisterFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { onRegistration, onRegistrationSuccess } = this.props

    if (
      !this.firstNameFieldRef.current ||
      !this.emailFieldRef.current ||
      !this.passwordFieldRef.current
    ) {
      throw new Error('Unable to access to all the fields')
    }

    const firstName = this.firstNameFieldRef.current.value
    const email = this.emailFieldRef.current.value
    const password = this.passwordFieldRef.current.value

    this.setState({ registrationInProgress: true }, async () => {
      const { customer, errors } = await onRegistration(
        firstName,
        email,
        password,
      )

      if (errors.length > 0) {
        this.setState({
          registrationInProgress: false,
          errors: errors,
        })

        return
      }

      if (!customer) {
        throw new Error(
          'No errors in registration process, but no data neither',
        )
      }

      this.setState({ registrationInProgress: false, errors: [] }, () => {
        onRegistrationSuccess(customer)
      })
    })
  }

  getErrorByField(source: string): ?string {
    const error = this.state.errors.find(oneError => oneError.field === source)

    if (!error) {
      return null
    }

    return error.message
  }

  render() {
    const {
      switchToLogInMode,
      privacyPoliciesPageUrl,
      termsOfServicesPageUrl,
      t,
    } = this.props
    const { registrationInProgress, uid, rgpdConditionAccepted } = this.state

    const firstNameError = this.getErrorByField('firstName')
    const emailError = this.getErrorByField('email')
    const passwordError = this.getErrorByField('password')

    let cguLabel = (
      <React.Fragment>
        {t('customerArea.registrationForm.gdpr.default')}
      </React.Fragment>
    )
    if (privacyPoliciesPageUrl && termsOfServicesPageUrl) {
      cguLabel = (
        <React.Fragment>
          {t('customerArea.registrationForm.gdpr.gtcAndPrivacyPolicies.part1')}
          <NativeLink
            href={termsOfServicesPageUrl}
            accented
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(
              'customerArea.registrationForm.gdpr.gtcAndPrivacyPolicies.part2',
            )}
          </NativeLink>
          {t('customerArea.registrationForm.gdpr.gtcAndPrivacyPolicies.part3')}
          <NativeLink
            href={privacyPoliciesPageUrl}
            accented
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(
              'customerArea.registrationForm.gdpr.gtcAndPrivacyPolicies.part4',
            )}
          </NativeLink>
          .
        </React.Fragment>
      )
    } else if (privacyPoliciesPageUrl) {
      cguLabel = (
        <React.Fragment>
          {t('customerArea.registrationForm.gdpr.privacyPolicies.part1')}
          <NativeLink
            href={privacyPoliciesPageUrl}
            accented
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('customerArea.registrationForm.gdpr.privacyPolicies.part2')}
          </NativeLink>
          .
        </React.Fragment>
      )
    } else if (termsOfServicesPageUrl) {
      cguLabel = (
        <React.Fragment>
          {t('customerArea.registrationForm.gdpr.privacyPolicies.gtc.part1')}
          <NativeLink
            href={termsOfServicesPageUrl}
            accented
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('customerArea.registrationForm.gdpr.privacyPolicies.gtc.part2')}
          </NativeLink>
          .
        </React.Fragment>
      )
    }

    return (
      <ModalDefaultContent>
        <ModalTitle>{t('customerArea.registrationForm.title')}</ModalTitle>

        <Form onSubmit={this.handleRegisterFormSubmit}>
          <TextInputField
            ref={this.firstNameFieldRef}
            type="text"
            name="firstName"
            label={t('customerArea.registrationForm.firstName')}
            defaultValue={
              process.env.NODE_ENV === 'development' ? `Norbert #${uid}` : null
            }
            errorMessage={firstNameError}
            autoComplete="off"
          />

          <TextInputField
            ref={this.emailFieldRef}
            type="text"
            name="email"
            label={t('customerArea.registrationForm.email')}
            defaultValue={
              process.env.NODE_ENV === 'development'
                ? `norbert+${uid}@custhome.app`
                : null
            }
            errorMessage={emailError}
            autoComplete="off"
          />

          <TextInputField
            ref={this.passwordFieldRef}
            type="password"
            name="password"
            label={t('customerArea.registrationForm.password')}
            defaultValue={
              process.env.NODE_ENV === 'development' ? '12345678' : null
            }
            errorMessage={passwordError}
            autoComplete="off"
          />

          <CheckboxInputField
            name="rgpd"
            label={cguLabel}
            checked={rgpdConditionAccepted}
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) =>
              this.setState({ rgpdConditionAccepted: e.target.checked })
            }
          />

          <Link onClick={() => switchToLogInMode()}>
            {t('customerArea.registrationForm.switchToLogin')}
          </Link>

          <SubmitButtonContainer>
            <SubmitButton
              disabled={registrationInProgress || !rgpdConditionAccepted}
              fluid
            >
              {t('customerArea.registrationForm.submit')}
            </SubmitButton>
          </SubmitButtonContainer>
        </Form>
      </ModalDefaultContent>
    )
  }
}

export default withTranslation()(CustomerRegistrationForm)
