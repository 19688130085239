// @flow

import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import Transition, { ENTERED } from 'react-transition-group/Transition'
import { Colors } from './Colors'
import { RobotoNormal } from './Typography/Fonts'
import CusthomeSvg from './Icons/CusthomeBlackSvg'
import { useTranslation } from 'react-i18next'

const ANIMATION_DURATION = 250

const FullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${Colors.background};

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${({ fadeIn }) =>
    fadeIn ? `transition: opacity ${ANIMATION_DURATION}ms ease-in-out` : null};
`

const Rotating = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const CircleWrapper = styled.div`
  animation-name: ${({ isRotate }) => (isRotate ? Rotating : 'none')};
  animation-duration: 1s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  width: 74px;
  height: 74px;
  circle {
    transition: stroke-dashoffset 0.25s;
  }
`

const CircleSvg = ({ loadingProgress }: { loadingProgress: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="74"
    height="74"
    viewBox="0 0 74 74"
  >
    <g>
      <circle
        stroke={Colors.white10}
        strokeWidth="2"
        fill="transparent"
        r="33"
        cx="37"
        cy="37"
      />
      <circle
        stroke={`${Colors.green}`}
        transform="rotate(-90 37 37)"
        strokeWidth="2"
        fill="transparent"
        r="33"
        cx="37"
        cy="37"
        strokeDasharray="207"
        strokeDashoffset={207 - (loadingProgress / 100) * 207}
      />
    </g>
  </svg>
)

const Text = styled.div`
  font-family: ${RobotoNormal.fontFamily};
  font-size: 18px;
  font-weight: ${RobotoNormal.weight};
  line-height: 1.33;
  text-align: center;
  color: ${Colors.white75};
  margin-top: 10px;
`

const LogoCusthome = styled.div`
  position: absolute;
  bottom: 15px;
  height: 20px;
`

export default ({
  isVisible,
  message,
  fadeIn = true,
  children,
  loadingProgress,
}: {|
  isVisible: boolean,
  message?: string,
  fadeIn?: boolean,
  children?: React.Node,
  loadingProgress?: number,
|}) => {
  const haveProgress = typeof loadingProgress === 'number'
  const { t, ready } = useTranslation()
  return (
    <Transition
      in={isVisible}
      timeout={fadeIn ? ANIMATION_DURATION : 0}
      unmountOnExit
    >
      {state => (
        <FullScreenWrapper isVisible={state === ENTERED} fadeIn={fadeIn}>
          {children || (
            <React.Fragment>
              <CircleWrapper isRotate={!haveProgress}>
                <CircleSvg
                  loadingProgress={haveProgress ? loadingProgress || 0 : 25}
                />
              </CircleWrapper>
              <Text>{message || ready ? t('loading') : '-'}</Text>
              <LogoCusthome>
                <CusthomeSvg />
              </LogoCusthome>
            </React.Fragment>
          )}
        </FullScreenWrapper>
      )}
    </Transition>
  )
}
