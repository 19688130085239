// @flow

import * as React from 'react'
import styled from 'styled-components'
import {
  Form,
  SubmitButton,
  SubmitButtonContainer,
} from '../../GuidelinesNew/Form'
import {
  RobotoMedium,
  RobotoNormal,
} from '../../GuidelinesNew/Typography/Fonts'
import { TextInputField } from '../../GuidelinesNew/Form/InputField'
import { ModalTitle, ModalDefaultContent } from '../../GuidelinesNew/Modal'
import { FakeLink } from '../../GuidelinesNew/Typography/Link'
import { Colors } from '../../GuidelinesNew/Colors'
import HelperSvg from '../../GuidelinesNew/Icons/HelperSvg'
import CheckGreenSvg from '../../GuidelinesNew/Icons/CheckGreenSvg'
import { withTranslation } from 'react-i18next'

const Link = styled(FakeLink)`
  display: block;
  margin-top: 10px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.weight};
  line-height: 30px;
`

const HelperContainer = styled.div`
  display: flex;
  margin: 20px 0;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.weight};
  line-height: 15px;
  color: ${Colors.white30};
  align-items: center;
`

const IconHelperContainer = styled.div`
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.white20};
`

const ConfirmationMessage = styled.div`
  font-family: ${RobotoNormal.fontFamily};
  font-weight: ${RobotoNormal.weight};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${Colors.white50};
`

const Green = styled.span`
  color: ${Colors.green};
`

const IconCheckContainer = styled.div`
  padding: 20px 0;
  text-align: center;
`

type OneErrorPayload = {| field: ?string, message: string |}

type Props = {|
  onRequestNewPassword: (email: string) => Promise<OneErrorPayload[]>,
  onRequestNewPasswordSuccess: () => any,
  onExitedAfterSuccess: ?() => any,
  switchToLogInMode: () => any,
  t: string => string,
|}

type State = {|
  requestNewPasswordInProgress: boolean,
  errors: OneErrorPayload[],
  newPasswordRequested: boolean,
|}

class RequestNewPasswordForm extends React.Component<Props, State> {
  +emailFieldRef: { current: null | React.ElementRef<'input'> }

  constructor(props: Props) {
    super(props)

    this.emailFieldRef = React.createRef()

    this.state = {
      errors: [],
      requestNewPasswordInProgress: false,
      newPasswordRequested: false,
    }
  }

  handleRegisterFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { onRequestNewPassword, onRequestNewPasswordSuccess } = this.props

    if (!this.emailFieldRef.current) {
      throw new Error('Unable to access to email field')
    }

    const email = this.emailFieldRef.current.value

    this.setState({ requestNewPasswordInProgress: true }, async () => {
      const errors = await onRequestNewPassword(email)

      if (errors.length > 0) {
        this.setState({
          requestNewPasswordInProgress: false,
          errors: errors,
        })

        return
      }

      this.setState(
        {
          requestNewPasswordInProgress: false,
          errors: [],
          newPasswordRequested: true,
        },
        () => {
          onRequestNewPasswordSuccess()
        },
      )
    })
  }

  getErrorByField(source: string): ?string {
    const error = this.state.errors.find(oneError => oneError.field === source)

    if (!error) {
      return null
    }

    return error.message
  }

  render() {
    const { onExitedAfterSuccess, switchToLogInMode, t } = this.props
    const { requestNewPasswordInProgress, newPasswordRequested } = this.state

    const emailError = this.getErrorByField('email')

    return (
      <ModalDefaultContent>
        <ModalTitle>{t('customerArea.requestNewPassword.title')}</ModalTitle>

        {!newPasswordRequested ? (
          <React.Fragment>
            <Form onSubmit={this.handleRegisterFormSubmit}>
              <TextInputField
                ref={this.emailFieldRef}
                type="text"
                name="email"
                label={t('customerArea.requestNewPassword.email')}
                defaultValue={
                  process.env.NODE_ENV === 'development'
                    ? `customer@custhome.app`
                    : null
                }
                errorMessage={emailError}
              />

              <HelperContainer>
                <IconHelperContainer>
                  <HelperSvg />
                </IconHelperContainer>
                {t('customerArea.requestNewPassword.description')}
              </HelperContainer>
              <Separator />

              <Link onClick={() => switchToLogInMode()}>
                {t('customerArea.requestNewPassword.switchToLogin')}
              </Link>
              <SubmitButtonContainer>
                <SubmitButton fluid disabled={requestNewPasswordInProgress}>
                  {t('customerArea.requestNewPassword.submit')}
                </SubmitButton>
              </SubmitButtonContainer>
            </Form>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <IconCheckContainer>
              <CheckGreenSvg />
            </IconCheckContainer>
            <ConfirmationMessage>
              {t('customerArea.requestNewPassword.confirmMessage.part1')}
              <Green>
                {this.emailFieldRef.current
                  ? this.emailFieldRef.current.value
                  : t('customerArea.requestNewPassword.confirmMessage.part2')}
              </Green>
              .
            </ConfirmationMessage>
            {onExitedAfterSuccess ? (
              <Link onClick={() => onExitedAfterSuccess()}>
                {t('customerArea.requestNewPassword.backToProgramView')}
              </Link>
            ) : null}
          </React.Fragment>
        )}
      </ModalDefaultContent>
    )
  }
}

export default withTranslation()(RequestNewPasswordForm)
