// @flow

import * as React from 'react'
import styled from 'styled-components'
import { RobotoBold } from '../Typography/Fonts'
import { device } from '../Devices'

export const IconInTheButton = styled.span`
  display: block;
  width: 16px;
  height: 16px;
`

const LeftIcon = styled(IconInTheButton)`
  margin-right: 5px;
`

export const TextInTheButton = styled.span`
  --font-size: 11px;

  display: block;
  height: calc(var(--font-size) - 1px);

  font-family: ${RobotoBold.fontFamily};
  font-size: var(--font-size);
  font-weight: ${RobotoBold.weight};
  text-transform: uppercase;
  line-height: 1;
  vertical-align: middle;
  letter-spacing: 1px;
  white-space: nowrap;
`

const StyledBaseButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  height: ${({ responsive }) => (responsive ? '30px' : '40px')};
  padding: 0 15px;
  border: 2px solid;
  border-radius: 40px;

  backdrop-filter: blur(1px);

  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  @media ${device.laptop} {
    height: 40px;
  }
`

export type BaseButtonProps = {|
  forwardAs?: 'input',
  children: React.Node,
  leftIcon?: React.Node,
  fluid?: boolean,
  responsive?: boolean,
  value?: any,
|}

export const BaseButton = ({
  children,
  leftIcon,
  ...otherProps
}: BaseButtonProps) => (
  <StyledBaseButton {...otherProps}>
    {leftIcon ? <LeftIcon>{leftIcon}</LeftIcon> : null}
    <TextInTheButton>{children}</TextInTheButton>
  </StyledBaseButton>
)
