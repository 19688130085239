// @flow

import Cookie from 'js-cookie'
import ClientOAuth2, { type Token } from 'client-oauth2'
import { defaultCookieOptions } from '../storage'
import ConfigurationShoppingCart from '../FlatConfigurator/MainMenu/Configuration/ShoppingCart/ConfigurationShoppingCart'

const authenticationCookieName = () =>
  process.env.REACT_APP_AUTH_COOKIE_NAME || 'customer_area_token'

export function readToken(authClient: ClientOAuth2): ?Token {
  const authCookie = Cookie.get(authenticationCookieName())
  if (!authCookie) {
    return null
  }

  const { expire_at: expireAt, ...rawToken } = JSON.parse(authCookie)

  const token = authClient.createToken(
    rawToken.access,
    rawToken.refresh_token,
    rawToken.type,
    rawToken.data,
  )

  if (expireAt) {
    const expireDate = new Date().setTime(expireAt)
    token.expiresIn(expireDate)
  }
  return token
}

export function readShoppingCart() {
  const shoppingCartCookie = Cookie.get('shopping_cart')
  if (!shoppingCartCookie) {
    return null
  }

  return JSON.parse(shoppingCartCookie)
}

export function writeShoppingCart(shoppingCart: ConfigurationShoppingCart) {
  Cookie.set('shopping_cart', JSON.stringify(shoppingCart), {
    ...defaultCookieOptions(document.location.protocol),
    expires: 1,
  })
}

export function deleteShoppingCart() {
  Cookie.remove(
    'shopping_cart',
    defaultCookieOptions(document.location.protocol),
  )
}

export function writeToken(token: Token) {
  const rawToken = {
    access: token.accessToken,
    refresh_token: token.refreshToken,
    type: token.tokenType,
    data: token.data,

    // Custom data to allow the persistance of the exact expiration date of the token
    expire_at: token.expires || null,
  }

  Cookie.set(authenticationCookieName(), JSON.stringify(rawToken), {
    ...defaultCookieOptions(document.location.protocol),
    expires: token.expires,
  })
}

export function deleteToken() {
  Cookie.remove(
    authenticationCookieName(),
    defaultCookieOptions(document.location.protocol),
  )
}
