// @flow

export const referrers = {
  FROM_CUSTOMER_AREA: 'from_customer_area',
  FROM_SELLER_AREA: 'from_seller_area',
}

export type REFERRER_VALUE = 'from_customer_area' | 'from_seller_area'

export const programPreviewHomePageRoute = (
  parameter?: string,
  value?: string | number,
): string => {
  if (
    parameter &&
    value !== undefined &&
    value !== null &&
    value !== 'global'
  ) {
    const parsedUrl = new URL(window.location.href)
    parsedUrl.searchParams.set(parameter, value.toString())
    return '/program' + parsedUrl.search
  } else {
    return '/program'
  }
}

export const configuratorHomePageRoute = (
  lotId: string,
  referrer: ?REFERRER_VALUE = null,
) => (!referrer ? `/lot/${lotId}` : `/lot/${lotId}?referrer=${referrer}`)

export const virtualTourPageRoute = (lotId: string) => `/lot/${lotId}`

export const customerAreaHomePageRoute = () => `/customer`

export const customerAreaLoginPageRoute = () => `/customer/login`

export const customerAreaRegisterPageRoute = () => `/customer/register`

export const customerAreaForgottenPasswordPageRoute = () =>
  `/customer/password/oops`

export const customerAreaResetPasswordPageRoute = () =>
  `/customer/password/reset`

export const customerAreaSavedConfigurationsPageRoute = () =>
  `/customer/configurations`

export const customerAreaSavedConfigurationDetailsPageRoute = (
  configurationId: string,
) => `/customer/configuration/${configurationId}`
