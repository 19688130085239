// @flow

import { type Axios } from 'axios'

export type Lot = {|
  area: number,
  available: boolean,
  floorLabel: string,
  floorId: string,
  displayOnFloors: { code: string, floorId: string }[],
  buildingId: string,
  buildingLabel: string,
  label: string,
  labelVirtualTour?: string,
  links: {
    virtual_tour?: string,
    plan: string,
    configurator?: string,
  },
  lotId: string,
  priceIncludingTaxes: {| amountIncludingTaxes: number, vatInPercent: number |},
  typology: string,
  controlledPrice?: boolean,
|}

export const PACKS = Object.freeze({
  BUILDING_3D: 'maquette3d',
  BUILDING_3D_VIRTUAL_TOUR: 'maquette3d+visite_virtuelle',
  BUILDING_3D_CONFIGURATOR: 'maquette3d+configurateur',
  VIRTUAL_TOUR: 'visite_virtuelle',
})

export type PackValue = $Values<typeof PACKS>

export type ProgramPayload = {|
  numberOfLots: number,
  programId: string,
  label: string,
  location: string,
  pack: PackValue,
  developerName: string,
  links: {
    privacy_policy?: string,
    terms_of_use?: string,
    program_preview?: string,
    contact?: string,
  },
|}

type FloorPreview = {
  compass: number,
  fieldOfView: number,
  camera: string,
  views: {
    /* camera: {
      position: Coordinate,
      target: Coordinate,
    }, */
    paths: {
      desktop: {
        jpg: string,
        webp: string,
      },
      mobile: { jpg: string, webp: string },
    },
    offset: number,
  }[],
}

type Building = {
  buildingId: string,
  buildingLabel: string,
  buildingCode: string,
  availableTypologies: ?(string[]),
  atLeastOneAvailableLot: boolean,
  floors: {
    floorId: string,
    floorPreview: FloorPreview,
    label: string,
    labelShort: string,
    level: number,
    availableTypologies: ?(string[]),
    atLeastOneAvailableLot: boolean,
  }[],
  generalPreview: FloorPreview,
  streetPreview: FloorPreview,
}

export type ProgramPreviewPayload = {|
  program: {
    buildings: Building[],
    generalPreview?: FloorPreview,
    streetPreview?: FloorPreview,
    modelizationPath: string,
    resolutionRatio: { mobile: number, desktop: number },
    developerWebsiteUrl: string,
    parkingIncluded: boolean,
  },
  lots: Lot[],
|}

export const fetchProgramPreview = async (
  httpClient: Axios,
  programPreviewApiEndpoint: string,
): Promise<ProgramPreviewPayload> =>
  (await httpClient.get(programPreviewApiEndpoint)).data

export const fetchProgram = async (
  httpClient: Axios,
): Promise<ProgramPayload> => (await httpClient.get('/api/program')).data

type OneFieldErrorPayload = {| field: ?string, message: string |}

export const postContact = async (
  httpClient: Axios,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
): Promise<OneFieldErrorPayload[]> => {
  try {
    await httpClient.post(
      '/api/contact',
      {
        firstName,
        lastName,
        email,
        phone,
      },
      {},
    )
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return err.response.data.map(oneError => ({
        field: oneError.source,
        message: oneError.message,
      }))
    }

    throw err
  }

  return []
}
